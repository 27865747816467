/**
 * Do not remove any keys!!
 * The shape of this file is to remain the same so that all options are know.
 *
 * If a key does not have a value, set the value to undefined.
 */
module.exports = {
  communityName: "Blackstone Farms",
  gtagId: undefined,
  logo: {
    src: "https://cdn.maxxpm.com/blackstonefarms/logoandbanners/blackstone_logo.png",
    alt: "Blackstone Farms Logo",
  },
  nav: {
    bannerSubtitle: undefined,
    bannerTitle: undefined,
    bannerCTA: undefined,
  },
  footer: {
    address: "1234 E 1500 S",
    cityStateZip: "Provo, UT 84606",
    phone: "(385) 236-5514",
    copyrightYear: "2024",
    logo: "https://cdn.maxxpm.com/blackstonefarms/logoandbanners/Blackstone Farms White Block logo@4x.png",
  },
  listMarkerSrc: "https://cdn.maxxpm.com/blackstonefarms/logoandbanners/bullet+point+blackstone+1.png",
  hasCalendlyPopup: false,
  calendlyLink: undefined,
  home: {
    banner: {
      image: "https://cdn.maxxpm.com/pineridge/pr_home.jpg",
      video: undefined,
      title: "Home is our Happy Place",
      subtitle: "Now Leasing!",
    },
    aboutUs: {
      paragraphs: [
        "The third-largest metropolitan area in Utah, Provo is a charming city home to several excellent museums and outdoor recreational sites. Located an hour south of Salt Lake City International Airport, with the convenience of the FrontRunner Commuter Rail at Provo Central Station and a well-developed bus system, Provo is an easily-accessible city. Not to mention, Provo residents are said to have the best work-life balance as businesses place a high emphasis on their employee’s personal time. For those who are the more outdoor-oriented, Provo is just down the road from Utah Lake State Park, Utah’s largest freshwater lake with abundant fish to catch, places to camp, and plenty of water to canoe, sail, and paddleboard. In the winter, residents boast of having Sundance Mountain Resort right in their backyard.",
        "Blackstone Farms is a premier apartment community with gorgeous modern units. Residents have access to several exclusive onsite amenities including a common area, recreational green space, and parking. Our luxurious apartments inspire residents to “work hard, play hard” with exclusive smart home upgrades, an open floor-plan, and excellent appliances and lighting.",
      ],
      image: {
        src: "https://cdn.maxxpm.com/blackstonefarms/logoandbanners/S+24.jpg",
        alt: "Downtown Provo",
      },
    },
    floorplanTitle: "Explore Our Floor Plan and Take a 3D Virtual Tour",
    floorplans: [
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/pineridge/pr_fp.png",
          alt: "Blackstone Farms Floor Plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=KF8YRtg4iNs",
          title: "3D Walkthrough",
        },
      },
    ],
    midContainerImage: "https://cdn.maxxpm.com/pineridge/pr_mid.jpg",
    imgRow: [
      {
        src: "https://cdn.maxxpm.com/blackstonefarms/logoandbanners/S+28.jpg",
        alt: "Image banner stock photo",
      },
      {
        src: "https://cdn.maxxpm.com/blackstonefarms/logoandbanners/baking.jpg",
        alt: "Image banner stock photo",
      },
      {
        src: "https://cdn.maxxpm.com/blackstonefarms/logoandbanners/S+27.jpg",
        alt: "Image banner stock photo",
      },
    ],
    animalPolicy: {
      img: {
        src: "https://cdn.maxxpm.com/blackstonefarms/logoandbanners/S+30.jpg",
        alt: "Animal policy stock photo",
      },
      policies: [
        "No more than 2 animals are allowed per property",
        "Cats/Dogs: $250/animal*",
        "$50/animal/month for animals under 50lbs*",
        "$75/animal/month for animals 50lbs+*",
        "One-time $75/animal DNA testing fee*",
        "Caged animals: $25/animal/month*",
        "Fish tanks are not permitted",
        "Rabbits and rodents of any kind are not permitted",
      ],
      breedRestrictions: [
        "Pit Bulls & Staffordshire Terriers",
        "Doberman Pinschers",
        "Rottweilers",
        "Chows",
        "Great Danes",
        "Presa Canarios/ Mastiffs",
        "Akitas",
        "Alaskan Malamutes",
        "Huskies",
        "German Shepherds",
        "Shar Peis",
        "Wolf-Hybrids",
      ],
    },
  },
  availability: {
    banner: {
      image: "https://cdn.maxxpm.com/blackstonefarms/logoandbanners/S 24.jpg",
      video: undefined,
      title: "Available Units",
      subtitle:
        "Unit availability is updated often from Monday-Friday during business hours. Evenings and weekends may not reflect accurate availability dates.",
    },
    hasWaitingList: false,
    waitingListJotformId: undefined,
    propertyGroup: "LEADS-BF",
  },
  faq: {
    banner: {
      image: "https://cdn.maxxpm.com/pineridge/pr_home.jpg",
    },
    dnaRequired: true,
  },
};
